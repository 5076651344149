import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (!window.localStorage.getItem('cookieAccepted')) {
      this.element.classList.remove('cookie-popup-accepted');
    }
  }

  onCloseClick() {
    window.localStorage.setItem('cookieAccepted', true);
    this.element.remove();
  }
}