import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['navigation', 'subMenuItem'];
  static values = {
    mode: String,
  };

  connect() {
    if (this.modeValue === '') {
      window.addEventListener('scroll', (e) => {
        e.preventDefault();
        this.scroll();
      });
    }

    const matchingMenuItem = this.subMenuItemTargets.find((linkElement) => {
      return linkElement.href === window.location.href;
    });

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      var immediateParent = this.getClosest(matchingMenuItem, 'li');
      if (immediateParent) {
        immediateParent.classList.add('active');
      }

      const parent = this.getClosest(matchingMenuItem, '.parent-menu-item');
      if (parent) {
        parent.classList.add('active');
        const parentMenuitem = parent.querySelector('.menu-item');
        if (parentMenuitem) {
          parentMenuitem.classList.add('active');
        }
        const parentOfParent = this.getClosest(parent, '.parent-parent-menu-item');
        if (parentOfParent) {
          parentOfParent.classList.add('active');
        }
      } else {
        const parentOfParent = this.getClosest(matchingMenuItem, '.parent-parent-menu-item');
        if (parentOfParent) {
          parentOfParent.classList.add('active');
        }
      }
    }
  }

  scroll() {
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
      this.element.classList.add('nav-sticky');
    } else {
      this.element.classList.remove('nav-sticky');
    }
  }

  onSubmenuParentLinkClick(e) {
    const submenu = e.target.nextElementSibling.nextElementSibling;
    submenu.classList.toggle('open');
  }

  onToggleClick(e) {
    e.preventDefault();

    if (this.navigationTarget.style.display === 'block') {
      e.currentTarget.classList.remove('open');
      this.navigationTarget.style.display = 'none';
    } else {
      e.currentTarget.classList.add('open');
      this.navigationTarget.style.display = 'block';
    }
  }

  getClosest(elem, selector) {
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) { }
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  }
}