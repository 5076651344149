import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'firstNameInput',
    'lastNameInput',
    'companyNameInput',
    'emailInput',
    'checkboxInput',
    'firstNameError',
    'lastNameError',
    'companyNameError',
    'emailError',
    'checkboxError',
  ];

  static values = {
    formInvalid: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: {
        firstName: null,
        lastName: null,
        companyName: null,
        email: null,
        checkbox: null,
      },
    },
  }

  async onSubmit(e) {
    e.preventDefault();

    if (this.validateForm()) {
      try {
        const response = await fetch('/api/registrations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            registration: {
              firstName: this.firstNameInputTarget.value,
              lastName: this.lastNameInputTarget.value,
              companyName: this.companyNameInputTarget.value,
              email: this.emailInputTarget.value,
              checkbox: this.checkboxInputTarget.checked,
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);

        window.location.href = '/sign-up/success';
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      this.renderErrors();
    }
  };

  validateForm() {
    let errors = {
      firstName: null,
      lastName: null,
      companyName: null,
      email: null,
      checkbox: null,
    };

    let invalid = false;

    if (!this.firstNameInputTarget.value || this.firstNameInputTarget.value === '') {
      errors.firstName = 'First name is required';
      invalid = true;
    }

    if (!this.lastNameInputTarget.value || this.lastNameInputTarget.value === '') {
      errors.lastName = 'Last name is required';
      invalid = true;
    }

    if (!this.companyNameInputTarget.value || this.companyNameInputTarget.value === '') {
      errors.companyName = 'Company name is required';
      invalid = true;
    }

    if (!this.emailInputTarget.value || this.emailInputTarget.value === '') {
      errors.email = 'Email is required';
      invalid = true;
    }

    if (!this.checkboxInputTarget.checked) {
      errors.checkbox = 'You must agree to privacy policy before continuing';
      invalid = true;
    }

    this.errorsValue = errors;
    this.formInvalidValue = invalid;

    return !invalid;
  }

  renderErrors() {
    this.firstNameErrorTarget.innerText = null;
    this.lastNameErrorTarget.innerText = null;
    this.companyNameErrorTarget.innerText = null;
    this.emailErrorTarget.innerText = null;
    this.checkboxErrorTarget.innerText = null;

    if (this.errorsValue.firstName) {
      this.firstNameErrorTarget.innerText = this.errorsValue.firstName;
    }

    if (this.errorsValue.lastName) {
      this.lastNameErrorTarget.innerText = this.errorsValue.lastName;
    }

    if (this.errorsValue.companyName) {
      this.companyNameErrorTarget.innerText = this.errorsValue.companyName;
    }

    if (this.errorsValue.email) {
      this.emailErrorTarget.innerText = this.errorsValue.email;
    }

    if (this.errorsValue.checkbox) {
      this.checkboxErrorTarget.innerText = this.errorsValue.checkbox;
    }
  }
}