import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item', 'button'];
  
  static values = {
    index: {
      type: Number,
      default: 0,
    },
  };

  connect() {
    this.interval = null;
    this.startInterval();
  }

  startInterval() {
    this.interval = setInterval(() => {
      this.next();
    }, 5000);
  }

  restartInterval() {
    clearInterval(this.interval);
    this.startInterval();
  }

  next() {
    this.itemTargets[this.indexValue].classList.add('d-none')
    this.buttonTargets[this.indexValue].classList.remove('tns-nav-active')

    this.indexValue = (this.indexValue + 1) % this.itemTargets.length;

    this.itemTargets[this.indexValue].classList.remove('d-none')
    this.buttonTargets[this.indexValue].classList.add('tns-nav-active')
  }

  onSwitch(e) {
    const index = parseInt(e.target.dataset.nav);

    this.itemTargets[this.indexValue].classList.add('d-none');
    this.buttonTargets[this.indexValue].classList.remove('tns-nav-active')

    this.itemTargets[index].classList.remove('d-none');
    this.buttonTargets[index].classList.add('tns-nav-active')
  
    this.indexValue = index;

    this.restartInterval();
  }
}
