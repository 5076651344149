import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['monthlySection', 'yearlySection'];

  static values = {
    period: {
      type: String,
      default: 'monthly',
    },
  }

  onPeriodSwitch() {
    this.periodValue = this.periodValue === 'monthly' ? 'yearly' : 'monthly';

    if (this.periodValue === 'monthly') {
      this.monthlySectionTarget.classList.remove('hide');
      this.yearlySectionTarget.classList.add('hide');
    } else {
      this.monthlySectionTarget.classList.add('hide');
      this.yearlySectionTarget.classList.remove('hide');
    }
  }
}