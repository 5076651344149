import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.adjustIframeHeight();  // Adjust height on connect
    window.addEventListener('resize', this.adjustIframeHeight.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.adjustIframeHeight.bind(this));
  }

  adjustIframeHeight() {
    if (window.innerWidth < 1024) {
      this.element.style.height = '2920px';
    } else {
      this.element.style.height = '1040px';
    }
  }
}
