import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    window.onscroll = () => {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        this.element.style.display = 'block';
      } else {
        this.element.style.display = 'none';
      }
    };
  }

  onClick(e) {
    e.preventDefault();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}